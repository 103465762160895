import React from 'react'
import { Projects } from './projects' 
import { Skills } from './skills'
import { Posts } from './posts'

export const RightPane = () => {
  return (
    <div>
      <Projects />
      <Skills />
      <Posts />
    </div>
  )
}

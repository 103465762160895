import React from "react";
import { Cpu } from "react-feather";
import { SkillList } from "./skill-list";
import Img from "gatsby-image";

export const Skills = () => {
  return (
    <div>
      <h2 className="is-size-3 is-family-secondary mb-3">
        <Cpu size={"1em"} />
        Skills
      </h2>
      <SkillList />
    </div>
  );
};

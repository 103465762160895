import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const SkillList = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAbout(name: { eq: "Ghoshan Jaganathamani" }) {
        skills {
          title
          description
          gatsbyImage(width: 100, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <div className="my-5">
      {data.contentfulAbout.skills.map((skill) => {
        return (
          <div className="skill-box box" key={skill.title}>
            <GatsbyImage image={getImage(skill)} alt={"TODO"} />
          </div>
        );
      })}
    </div>
  );
};

import React, { useContext, useState } from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { ThemeContext } from "../utils/store";

import { Twitter, GitHub, Instagram, Linkedin, Moon } from "react-feather";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const IOSSwitch = withStyles((theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const LeftPane = ({ data, changeTab, current }) => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleChange = (event) => {
    toggleTheme();
  };

  const toResume = () => {
    navigate("https://resume.ghoshan.dev");
  };
  // console.log("data", data)
  const image = getImage(data.image);
  const btnCls = "button is-light is-small has-text-weight-semibold"

  if (data) {
    return (
      <div className="left-pane">
        <GatsbyImage image={image} alt={"TODO"} />
        {/* <Img fluid={data.image.fluid} imgStyle={{ borderRadius: "5px" }} style={{ marginBottom: "2em" }} /> */}
        <h1 className="is-size-3 is-size-4-mobile has-text-weight-semibold is-family-secondary mb-4">
          {data.name}
        </h1>
        <p className="mb-2 has-text-weight-bold">{data.shortDescription}</p>
        <div className="buttons mb-2">
          <button
            className={`${btnCls} is-primary ${current === "Home" ? "is-focused" : ""
              }`}
            onClick={() => changeTab("Home")}
          >
            Home
          </button>
          <button
            className={`${btnCls} is-success ${current === "About" ? "is-focused" : ""
              }`}
            onClick={() => changeTab("About")}
          >
            About
          </button>
          <button
            className={`${btnCls} is-warning ${current === "Posts" ? "is-focused" : ""
              }`}
            onClick={() => changeTab("Posts")}
          >
            Posts
          </button>
          <button
            className={`${btnCls} is-info`}
            onClick={() => toResume()}
          >
            Resume
          </button>
        </div>
        <div className="is-family-secondary mb-2">
          <a href={data.github}>
            <GitHub size={"1em"} />
            ghoshanjega
          </a>{" "}
          <br />
          <a href={data.twitter}>
            <Twitter size={"1em"} />
            GhoshanTheGreat
          </a>{" "}
          <br />
          <a href={data.instagram}>
            <Instagram size={"1em"} />
            ghoshanthegreat
          </a>{" "}
          <br />
          <a href={data.linkedin}>
            <Linkedin size={"1em"} />
            Ghoshan Jaganathamani
          </a>{" "}
          <br />
        </div>

        <FormControlLabel
          control={
            <IOSSwitch
              checked={theme === "dark" ? true : false}
              onClick={() => toggleTheme}
              onChange={handleChange}
              name="checkedB"
            />
          }
          label={
            <Moon
              size={"1.5em"}
              fill={theme === "dark" ? "white" : "transparent"}
            />
          }
        ></FormControlLabel>
      </div>
    );
  }
  return <></>;
};

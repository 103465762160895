import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const RightPaneAbout = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAbout(name: { eq: "Ghoshan Jaganathamani" }) {
        longDescription {
          raw
        }
      }
    }
  `);
  const richText = data.contentfulAbout.longDescription;
  return <div className="post">{richText && renderRichText(richText)}</div>;
};

import React from "react";
import { Layers, GitHub, Youtube, File, Link } from "react-feather";

const Project = ({ title, shortDescription, links, tags }) => {
  return (
    <div className="column  is-half">
      <div className="box p-4 px-5 w-100">
        <h5 className="is-size-5 has-text-weight-semibold mb-0 is-family-secondary">
          {title}
        </h5>
        <p className="mb-1">{shortDescription}</p>
        <div className="tags are-normal mb-0">
          {tags && tags.map(tag => <span class="tag is-success mr-1 is-family-monospace has-text-weight-semibold">{tag}</span>)}

        </div>
        {links}

      </div>

    </div>
  );
};

export const Projects = () => {
  return (
    <div className="mb-4">
      <h2 className="is-size-3 is-family-secondary mb-3">
        <Layers size={"1em"} />
        Projects
      </h2>
      <div className="columns is-desktop is-multiline">
        <Project
          title={"Party"}
          shortDescription={
            "Multiplayer games. Under construction 🚧"
          }
          links={[
            <>
              <a
                href="https://github.com/ghoshanjega/party"
                className="mr-2"
              >
                <GitHub className="mr-0" />
              </a>
              <a
                href="https://party-games.ghoshan.dev/"
                className="mr-2"
              >
                <Link className="mr-0 has-text-info" />
              </a>
            </>



          ]}
          tags={["react", "threejs", "socketio", "k8s", "fly.io", "multiplayer", "apple"]}
        />
        <Project
          title={"Stonkgen"}
          shortDescription={
            "Stock execution system"
          }
          links={[
            <a
              href="https://github.com/ghoshanjega/party"
              className="mr-2"
            >
              <GitHub className="mr-0" />
            </a>

          ]}
          tags={["react", "redux toolkit", "express", "lowdb", "coding exercise"]}
        />
        <Project
          title={"HK covid analysis"}
          shortDescription={
            "Covid data extraction for blog post"
          }
          links={[
            <a
              href="https://github.com/ghoshanjega/HK-Covid-analysis"
              className="mr-2"
            >
              <GitHub className="mr-0" />
            </a>

          ]}
          tags={["jupyter"]}
        />
        <Project
          title={"Building as AI caching system for CCN"}
          shortDescription={
            "Final Year Project. Winner of IEEE Computational Award 2019 and HKUST Presidents award semi-finalist."
          }
          links={[
            <a
              href="https://docs.google.com/presentation/d/1h8XzWHdfqGQslJfYvklCLVfcj8pjBqX85GUr3Ck5lYw/"
              className="mr-2"
            >
              <File className="mr-0 has-text-link" />
            </a>,
            <a
              href="https://docs.google.com/presentation/d/1Rzp0RVG6pLdUqXsFgtxPSJvKf8Ny4xZQNaUpZgUCvO4/"
              className="mr-2"
            >
              <File className="mr-0 has-text-link" />
            </a>,
          ]}
          tags={["CNN-DCC"]}
        />
        <Project
          title={"Robotic Grasp 🦾"}
          shortDescription={
            "My findings on Robotic Grasp optimization and visualization."
          }
          links={[
            <a href="https://github.com/ghoshanjega/Robotic-Grasp-Optimization">
              <GitHub className="mr-0" />
            </a>,
          ]}
          tags={["force closure"]}
        />
        <Project
          title={"IMU Movements"}
          shortDescription={"A Flying toilet visualizes Arduino IMU movements."}
          links={[
            <a
              href="https://github.com/ghoshanjega/Arduino-IMU-Visualization"
              className="mr-2"
            >
              <GitHub className="mr-0" />
            </a>,
            <a href="https://www.youtube.com/watch?v=Bll1jZfpnbo">
              <Youtube className="mr-0 has-text-danger" />
            </a>,
          ]}
          tags={["socket.io", "arduino"]}
        />

        <Project
          title={"Dystopian World"}
          shortDescription={"Exploration on Three JS."}
          links={[
            <a
              href="https://dystopian.netlify.app/demo/index.html"
              className="mr-2"
            >
              <Link className="mr-0 has-text-info" />
            </a>,
          ]}
        />

        <Project
          title={"Guftagoo"}
          shortDescription={
            " 1st Runner-up at HKUST Hackathon. Online Debating app."
          }
          links={[
            <a href="https://github.com/ghoshanjega/Guftagoo" className="mr-2">
              <GitHub className="mr-0" />
            </a>,
          ]}
        />
      </div>
    </div>
  );
};

import React from 'react'
import { Edit } from 'react-feather'
import { PostList } from './post-list'

export const Posts = ({data}) => {
  return (
    <div>
       <h2 className="is-size-3 is-family-secondary mb-3"><Edit size={"1em"} />Posts</h2>
        <PostList />
    </div>
  )
}




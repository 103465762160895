import React, { useState, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";
import { LeftPane } from "../components/left-pane";
import { RightPane } from "../components/right-pane";
import { RightPaneAbout } from "../components/right-pane-about";
import { RightPanePosts } from "../components/right-pane-posts";
import { ThemeContext } from "../utils/store";

// import "../styles/home.scss"

const IndexPage = ({ data, location }) => {
  const [tab, setTab] = useState("Home");

  useEffect(() => {
    if (location.state) {
      setTab(location.state.tab);
    }
  }, []);

  const changeTab = (tabTo) => {
    if (tabTo !== tab) {
      setTab(tabTo);
    }
  };

  console.log("datas", data);

  const { theme } = useContext(ThemeContext);
  return (
    <div className={theme}>
      {/* <Bkg /> */}
      <div className="section">
        <SEO
          title="Ghoshan Jaganathamani"
          description="Personal blog of author Ghoshan Jaganathamani"
        />
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-3">
              <LeftPane
                data={data.contentfulAbout}
                changeTab={changeTab}
                current={tab}
              />
            </div>
            <div className="column is-hidden-mobile"></div>
            <div className="column is-8">
              {tab === "Home" ? <RightPane /> : <div />}
              {tab === "About" ? <RightPaneAbout /> : <div />}
              {tab === "Posts" ? <RightPanePosts /> : <div />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  {
    contentfulAbout(name: { eq: "Ghoshan Jaganathamani" }) {
      id
      github
      discord
      instagram
      linkedin
      name
      shortDescription
      twitter
      image {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      skills {
        title
        description
      }
    }
  }
`;
